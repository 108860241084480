





























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheSmsStep',
  components: {
    CodeInputStep: () =>
      import(
        '@/components/Modals/Default/TwoFactorAuth/components/CodeInputStep.vue'
      ),
  },
})
export default class TheSmsStep extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
  // it's only for first rendering, but you must propogate it!
  @Prop({ type: Number, default: 4 }) readonly length!: number
  @Prop({ type: Number, default: -1 }) readonly timeout!: number
  @Prop({ type: String, default: '' }) readonly error!: string

  changePhone () {
    window.goal('loginAction', { login: 'SMS: кнопка "Изменить номер"' })
    this.$emit('changePhone')
  }

  resendCode () {
    window.goal('loginAction', { login: 'SMS: кнопка "Отправить еще раз"' })
    this.$emit('resend-code')
  }
}
